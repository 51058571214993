@import '../style-variables/sizes';
@import 'variables';

.keb-ge__page {
  padding: $PagePadding;

  &--pad {
    &-none {
      padding: 0;
    }

    &-v {
      padding: $PagePadding 0;
    }

    &-h {
      padding: 0 $PagePadding;
    }
  }
}

.keb-ge__full-height {
  min-height: calc(100vh - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));

  &--without-header {
    // `display: flex` to be able to enlarge a child element to the full page height with 'flex-grow: 1'
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{$Spacing16} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
  }
}

.keb-ge__error {
  margin: $Spacing4 0;
  padding: $Spacing4;
  color: var(--f-red);
  font-size: 0.875rem; // 14px
  border-radius: $BorderRadiusLarge;
  background-color: var(--f-red-tint);
}

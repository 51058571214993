@import '../style-variables/library';

.keb-ge {
  &__sub-line {
    margin-bottom: $Spacing4;
    color: var(--grey-08);
    font-size: 1.125rem; // 18px
    line-height: 1.5rem; // 24px;

    &-2 {
      margin-bottom: $Spacing4;
      color: var(--grey-08);
      font-size: 1rem;
      line-height: 1.25;
    }
  }

  &__headline {
    color: var(--black);
    font-size: 1.5rem; // 24px
    font-weight: $FontWeightBold;
    letter-spacing: 0.35px;
    line-height: 1.17;
  }

  &__headline-2 {
    margin-bottom: $Spacing4;
    font-size: 1.2rem;
    font-weight: $FontWeightBold;
  }
}

@import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/themes/legeartis.main';
@import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/themes/kerberos.main';
@import 'global-styles';
@import 'third-party-styles';
@import './global-styles/modal';

@include defineDefaultFontFace();

:root {
  // This color variable is overwritten by the AppBackgroundColorService
  // depending on desired background color for the current page
  --background-color: var(--white);
  --safe-area-inset-top: 0;
  --safe-area-inset-bottom: 0;

  /* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
  // noinspection CssInvalidFunction
  @supports (height: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top, 0);
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 0);
  }
}

html {
  background-color: #212529;
}

body {
  max-width: 50rem;
  min-height: 100vh;
  margin: 0 auto;
  padding-bottom: var(--safe-area-inset-bottom);
  // Disable blue highlight on clicks
  -webkit-tap-highlight-color: transparent;
}

em {
  font-style: italic;
}

strong {
  font-weight: $FontWeightBold;
}

.keb-navbar-notch {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--safe-area-inset-top);
  z-index: 99;

  &--hidden {
    display: none;
  }
}

keb-header {
  .keb-header {
    top: var(--safe-area-inset-top) !important;
  }
}

// Css for the overlay that shows the keb-action-sheet element
.keb-custom-action-sheet-overlay {
  display: grid;
  // Show the action sheet at the bottom
  align-items: end;
  // This makes sure the backdrop click works SEE: https://github.com/angular/components/issues/21656
  pointer-events: none !important;

  // Add a space after the action-sheet with the same height as the app menu bar
  keb-action-sheet {
    pointer-events: auto;

    &::after {
      content: ' ';
      display: block;
      width: 100vw;
      height: calc(var(--safe-area-inset-bottom) + #{$MenuBarHeight});
      background-color: var(--white);
    }
  }
}
